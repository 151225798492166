var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-main", [
    _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
      _vm._v("Usuário"),
    ]),
    _c("div", { staticClass: "d-sm-flex" }, [
      _c(
        "div",
        { staticClass: "pa-2 pl-0" },
        [
          _c(
            "v-card",
            { attrs: { width: "300", "max-width": "300", tile: "" } },
            [
              _c(
                "div",
                { staticClass: "text-center pa-5" },
                [
                  _c("v-avatar", { attrs: { size: "140" } }, [
                    _c("img", { attrs: { src: _vm.thumbnail, width: "100%" } }),
                  ]),
                  _c("h4", { staticClass: "mt-4" }, [
                    _vm._v(
                      _vm._s(_vm.user.firstName) +
                        " " +
                        _vm._s(_vm.user.lastName)
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list",
                { staticClass: "py-3" },
                [
                  _c(
                    "v-list-item-group",
                    { attrs: { color: "primary" } },
                    _vm._l(_vm.items, function (item, i) {
                      return _c(
                        "v-list-item",
                        { key: i, attrs: { to: item.path, id: item.label } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", {
                                domProps: { textContent: _vm._s(item.icon) },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(item.label) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.user
        ? _c(
            "div",
            { staticClass: "pa-2 w-full" },
            [
              _c(
                "vue-page-transition",
                { attrs: { name: "fade-in-up" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
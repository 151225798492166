<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Usuário</h2>

    <div class="d-sm-flex">
      <div class="pa-2 pl-0">
        <v-card width="300" max-width="300" tile>
          <div class="text-center pa-5">
            <v-avatar size="140">
              <img :src="thumbnail" width="100%" />
            </v-avatar>

            <h4 class="mt-4">{{ user.firstName }} {{ user.lastName }}</h4>
          </div>

          <v-list class="py-3">
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in items" :key="i" :to="item.path" :id="item.label">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.label"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
      <div v-if="user" class="pa-2 w-full">
        <vue-page-transition name="fade-in-up">
          <router-view />
        </vue-page-transition>
      </div>
    </div>
  </v-main>
</template>

<script>
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  props: ['id'],

  data() {
    return {
      form: {},
    };
  },

  computed: {
    ...mapState('cadastrosGerenciais/usuario', {
      user: (state) => state.user,
    }),

    ...mapGetters({
      thumbnail: 'cadastrosGerenciais/usuario/getUserThumbnail',
    }),

    items() {
      return [
        { label: 'Dados de acesso', icon: 'mdi-account', path: `/cadastros-gerenciais/usuarios/${this.id}/dados` },
        { label: 'Permissões', icon: 'mdi-shield-key', path: `/cadastros-gerenciais/usuarios/${this.id}/permissoes` },
        {
          label: 'Redefinir senha',
          icon: 'mdi-lock',
          path: `/cadastros-gerenciais/usuarios/${this.id}/change-password`,
        },
        {
          label: 'Autenticação (2FA)',
          icon: 'mdi-shield-check',
          path: `/cadastros-gerenciais/usuarios/${this.id}/authentication`,
        },
        {
          label: 'Editor de menu',
          icon: 'mdi-shield-key',
          path: `/cadastros-gerenciais/usuarios/${this.id}/editorMenu`,
        },
        {
          label: 'Estabelecimentos',
          icon: 'mdi-office-building',
          path: `/cadastros-gerenciais/usuarios/${this.id}/estabelecimentos`,
        },
      ];
    },

    establishmentsParsed() {
      let items = [];
      let groupMatriz = groupBy(this.establishments, 'socialName');
      groupMatriz = Object.entries(groupMatriz).map(([key, value]) => ({
        company: key,
        establishments: value,
      }));

      groupMatriz = sortBy(groupMatriz, ['socialName']);
      groupMatriz.forEach((item, index) => {
        if (index > 0) items.push({ divider: true });
        const group = item.company;
        items.push({ header: group });
        item.establishments.forEach((innerItem) => {
          innerItem.group = group;
          items.push(innerItem);
        });
      });

      return items;
    },
  },

  created() {
    this.fetchUser(this.id);
    this.getRoles();
  },

  methods: {
    ...mapActions('cadastrosGerenciais/usuario', ['fetchUser', 'getRoles', 'changeRoles']),
  },
};
</script>
